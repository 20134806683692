<template>
  <div class="content">您访问的页面不存在！</div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.content {
  font-weight: bold;
  font-size: 20px;
  color: #cccccc;
  width: 100vw;
  height: calc(100vh - 63px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
